import { Box, Button, Typography } from "@mui/material";
import React from "react";

const TermsConditions = () => {
    return (
        <div style={{ backgroundColor: '#000000', padding: '40px', minHeight: '100vh' }}>
            <Box 
                align='center' 
                justifyContent='center' 
                sx={{ 
                    width: { xs: '83%', md: '60%' }, 
                    margin: 'auto', 
                    padding: '20px', 
                    backgroundColor: '#000000', 
                    borderRadius: '10px', 
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)' 
                }}
            >
                <Typography align="center" sx={{ fontSize: { xs: '24px', sm: '36px' }, fontWeight: '700', marginBottom: '20px', color: '#ffffff' }}>
                    Terms & Conditions
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        By accessing or using our services, you agree to be bound by these terms and conditions. If you disagree with any part of the terms, you may not access the service.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        <strong>Modification of Terms:</strong> We reserve the right to modify or replace these terms at any time. Changes will be posted on this page and will take effect immediately upon posting. Your continued use of the service after any changes constitutes your acceptance of the new terms.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        <strong>User Responsibilities:</strong> You agree to use our services only for lawful purposes and in accordance with these Terms. You agree not to:
                        <ul style={{ color: '#ffffff' }}>
                            <li>Use the service in any way that violates any applicable national or international law.</li>
                            <li>Impersonate or attempt to impersonate GrowitUp, an employee of GrowitUp, another user, or any other person or entity.</li>
                            <li>Engage in any conduct that restricts or inhibits anyone's use or enjoyment of the service, or which, as determined by us, may harm GrowitUp or users of the service or expose them to liability.</li>
                        </ul>
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        <strong>Intellectual Property:</strong> All content on this site, including text, graphics, logos, images, as well as the arrangement thereof, is the property of GrowitUp or its content suppliers and is protected by international copyright laws. Unauthorized use of the content may violate copyright, trademark, and other laws.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        <strong>Links to Other Websites:</strong> Our service may contain links to third-party websites or services that are not owned or controlled by GrowitUp. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party sites or services.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        <strong>Limitation of Liability:</strong> In no event shall GrowitUp, its directors, employees, partners, agents, suppliers, or affiliates be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, arising out of or related to your use of the service.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        <strong>Governing Law:</strong> These terms shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions. Any legal action arising out of or related to these Terms will be brought exclusively in the courts located in [Your City].
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        <strong>Termination:</strong> We may terminate or suspend access to our service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        <strong>Severability:</strong> If any provision of these Terms is found to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        <strong>Contact Us:</strong> If you have any questions about these Terms, please contact us at <a href="mailto:hello@growitup.in" style={{ color: '#37ca37' }}>hello@growitup.in</a>.
                    </div>
                </Typography>

                <Button
                    type="submit"
                    onClick={() => window.location.href = '/'} // Adjust to your homepage URL
                    sx={{
                        margin: '5px',
                        color: '#37ca37', 
                        borderColor: '#37ca37',
                        ':hover': {
                            boxShadow: '0 0 10px rgba(255, 152, 0, 0.5), 0 0 20px rgba(255, 152, 0, 0.3)'
                        }
                    }}
                    variant="outlined"
                >
                    <div className="montserrat-400">
                        Give Me MY Free GUIDE
                    </div>
                </Button>
            </Box>
        </div>
    );
}

export default TermsConditions;
