import React from 'react';
import { Box, Grid, Typography, Avatar } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

const About = () => {
    return (
        
        <Box
            sx={{
                backgroundColor: '#000000',
                minHeight: '100vh',
                padding: '50px 20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    width: { xs: '100%', sm: '90%', md: '80%', lg: '70%' },
                    backgroundColor: '#000000',
                    border: 'solid 1px #2F3645',
                    borderRadius: '10px',
                    padding: '20px',
                }}
            >
                <Typography
                    
                    sx={{
                        color: '#37ca37',
                        letterSpacing: '0.5px',
                        marginBottom: '20px',
                        textAlign: 'center',
                        fontSize:'48px'
                    }}
                >
                    <div className='montserrat-700'>
                    About Us
                    </div>
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography
                            variant="body1"
                            sx={{
                                color: '#ffffff',
                                fontSize: '16px',
                                lineHeight: '1.6',
                                marginBottom: '20px',
                            }}
                        >
                            We are a marketing agency specializing in Paid Advertising. Our mission is
                            straightforward: to deliver exceptional results with a focused approach.
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: '#ffffff',
                                fontSize: '16px',
                                lineHeight: '1.6',
                                marginBottom: '20px',
                            }}
                        >
                            Founded by Ishant Singh Rajput and Priynk Verma, we pride ourselves on our
                            ability to maximize your ROI through targeted advertising strategies.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={6} sm={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        marginBottom: '20px',
                                    }}
                                >
                                    <Avatar
                                        alt="Founder 1"
                                        src="/founder1.jpg"
                                        sx={{ width: 120, height: 120, marginBottom: '10px' }}
                                    />
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: '#ffffff',
                                            fontWeight: 'bold',
                                            marginBottom: '5px',
                                        }}
                                    >
                                        Ishant Singh Rajput
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: '#ffffff',
                                            marginBottom: '5px',
                                        }}
                                    >
                                        Founder 
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        marginBottom: '20px',
                                    }}
                                >
                                    <Avatar
                                        alt="Founder 2"
                                        src="/founder2.jpg"
                                        sx={{ width: 120, height: 120, marginBottom: '10px' }}
                                    />
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: '#ffffff',
                                            fontWeight: 'bold',
                                            marginBottom: '5px',
                                        }}
                                    >
                                        Priynk Verma
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: '#ffffff',
                                            marginBottom: '5px',
                                        }}
                                    >
                                       Founder
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    color: '#ffffff',
                                    marginBottom: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <LocationOnOutlinedIcon
                                    sx={{ fontSize: 'small', marginRight: '5px' }}
                                />
                                Remote Work
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: '#ffffff',
                                    marginBottom: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <PhoneOutlinedIcon sx={{ fontSize: 'small', marginRight: '5px' }} />
                                +1 000 00 0 
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: '#ffffff',
                                    marginBottom: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <EmailOutlinedIcon sx={{ fontSize: 'small', marginRight: '5px' }} />
                                hello@growitup.in
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default About;
