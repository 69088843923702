import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import calendyUrl from '../global';

const DownloadSuccess = () => {
    
    const [showTick, setShowTick] = useState(false);

    // Show the tick mark after a delay
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowTick(true);
        }, 1000); // Delay of 1 second

        return () => clearTimeout(timer); // Cleanup the timer on unmount
    }, []);

    const handleButtonClick = () => {
        
        window.open(calendyUrl, '_blank', 'noopener,noreferrer');

    };

    return (
        <Box
            sx={{
                backgroundColor: '#000000', // Black background for the entire page
                height: '100vh', // Full height
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px',
                    backgroundColor: '#000000',
                    border: 'solid 1px #2F3645',
                    borderRadius: '10px',
                    width: { xs: '90%', sm: '80%', md: '60%', lg: '50%' },
                    margin: '20px auto',
                    textAlign: 'center',
                }}
            >
                {/* Tick Mark Animation */}
                {showTick && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '50%',

                            width: '70px',
                            height: '70px',
                            marginBottom: '20px',
                            animation: 'scale-up 0.5s ease-in-out forwards',
                        }}
                    >
                        <CheckCircleOutlineIcon sx={{ color: '#37ca37', fontSize: '40px' }} />
                    </Box>
                )}

                <Typography sx={{ color: '#ffffff', fontSize: { xs: '18px', md: '24px' }, marginBottom: '10px' }}>
                    Download Successful!
                </Typography>
                <Typography
                    gutterBottom
                    sx={{
                        color: '#ffffff',
                        fontSize: { xs: '16px', md: '20px' },
                    }}
                >
                    <div className="montserrat-400 ">

                    Your file has been downloaded successfully. Enjoy your guide!
                    </div>
                </Typography>
                <Typography
                   gutterBottom
                    sx={{
                        color: '#ffffff',
                        fontSize: { xs: '16px', md: '20px' },
                    }}
                >
                    <div className="montserrat-400 ">

                    Need more information? Feel free to contact us for further assistance.
                    </div>
                </Typography>
               
                <Typography
                   gutterBottom
                    sx={{
                        color: '#ffffff',
                        fontSize: { xs: '10px', md: '15px' },
                    }}
                >
                    <div className="montserrat-200 ">
                    Love Frome growitup ❤️
                    </div>
                </Typography>
               

                
                {/* Outlined Button at the bottom */}
                <Button
                    onClick={handleButtonClick}
                    variant="outlined" // Changed to outlined variant
                    sx={{
                        marginTop: '20px',
                        borderColor: '#37ca37', // Green border color
                        color: '#37ca37', // Text color
                        padding: '5px 10px', // Adjust padding for text sizing
                        ':hover': {
                            // backgroundColor: '#37ca37',
                            boxShadow: '0 0 10px rgba(255, 152, 0, 0.5), 0 0 20px rgba(255, 152, 0, 0.3)'
                        }
                    }}
                >
                    Book Call now
                </Button>
            </Box>
        </Box>
    );
};

export default DownloadSuccess;
