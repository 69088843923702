import { Box, Button, Grid, Typography, IconButton } from "@mui/material";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X'; import React from "react";
import calendyUrl from "../global";

const BottomNav = () => {
    const handleButtonClick = () => {
        window.open(calendyUrl, '_blank', 'noopener,noreferrer');
    };

    return (
        <>
            <div>
                <Box align='center' justifyContent='center'>
                    <Box sx={{ width: { xs: '83%', md: '60%' } }}>
                        <Typography align="center" sx={{ fontSize: { xs: '21px', sm: '56px' }, textTransform: 'capitalize' }}>
                            <div className="montserrat-700 customgreen">Still not convinced?</div>
                        </Typography>
                        <Typography align="center" sx={{ fontSize: { xs: '16px', sm: '22px' } }}>
                            <div className="montserrat-400">You read the whole page, now why don’t you meet me directly at Free 15-Minute Demo Call?</div>
                        </Typography>
                        <br />
                        <Grid align='center'>
                            <Button
                                onClick={handleButtonClick}
                                variant="contained"
                                size='large'
                                sx={{
                                    textTransform: 'uppercase',
                                    borderRadius: '100px',
                                    padding: '15px 20px',
                                    fontSize: { xs: '14px', sm: '27px' },
                                    fontWeight: '700',
                                    backgroundColor: '#37ca37',
                                    color: 'white',
                                    marginBottom: '25px',
                                    ':hover': {
                                        backgroundColor: '#37ca37',
                                        boxShadow: '0 0 10px rgba(255, 152, 0, 0.5), 0 0 20px rgba(255, 152, 0, 0.3)',
                                    }
                                }}
                            >
                                <div className="montserrat-700">Click here to book your call</div>
                            </Button>
                        </Grid>
                        <br /><br />
                        <Typography align="center" sx={{ fontSize: { xs: '18px' } }}>
                            <div className="montserrat-300">Have A General Inquiry?</div>
                        </Typography>
                        <Typography align="center" sx={{ fontSize: { xs: '14px' } }}>
                            <div className="montserrat-200">If you have a general inquiry and would like to speak to our expert team, you can contact us via email at: hello@growitup.in</div>
                        </Typography>
                    </Box>
                </Box>
                <br />
                <Typography align="center" sx={{ fontSize: '14px', fontWeight: '700', letterSpacing: '3px' }}>
                    <div className="montserrat-700"><span className="custom-font">GrowitUp</span> Online Services</div>
                    <div className="montserrat-300">All Rights Reserved | All Wrongs Reversed</div>
                </Typography>
                <Typography align="center" sx={{ fontSize: '14px', color: '#ffffff', padding: '2px' }}>
                    <div className="montserrat-300">
                        <a href="/terms-conditions" style={{ textDecoration: 'none', color: '#ffffff' }}>Terms & Conditions</a> |
                        <a href="/privacy-policy" style={{ textDecoration: 'none', color: '#ffffff' }}> Privacy Policy</a> |
                        <a href="/disclaimer" style={{ textDecoration: 'none', color: '#ffffff' }}> Disclaimer</a>
                    </div>
                </Typography>


                {/* <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton onClick={() => window.open('https://www.instagram.com/growitup_in', '_blank')} aria-label="Instagram">
                        <InstagramIcon />
                    </IconButton>
                    <IconButton onClick={() => window.open('https://www.facebook.com/people/Growitup/100095074315108/', '_blank')} aria-label="Facebook">
                        <FacebookIcon />
                    </IconButton>
                    <IconButton onClick={() => window.open('https://www.linkedin.com/company/growitup-in', '_blank')} aria-label="LinkedIn">
                        <LinkedInIcon />
                    </IconButton>
                    <IconButton onClick={() => window.open('https://x.com/Growitup_in', '_blank')} aria-label="Twitter">
                        <XIcon />
                    </IconButton>
                </Box> */}

                <Box sx={{ height: '50px', width: '100%' }}></Box>
            </div>
        </>
    );
}

export default BottomNav;
