import React from "react";
import { Container, Typography, Button, Box, Grid } from '@mui/material';
import calendyUrl from "../global";

const Hero1 = () => {

    // const openCalendlyPopup = () => {
    //     window.Calendly.initPopupWidget({ url: 'https://calendly.com/ishantsinghrajput143/appointment' }); 
    //     return false;
    // };

    const handleButtonClick = () => {
        
        window.open(calendyUrl, '_blank', 'noopener,noreferrer');

    };

    return (
        <Container
            maxWidth="lg"
            className="App" // Use provided CSS class
            sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                padding: { xs: 2, sm: 4 },
            }}
        >
            <Grid container spacing={4}>
                <Grid item xs={12}>
                <Typography
                            align="center"
                            variant="h4"
                            gutterBottom
                            sx={{
                                fontWeight: 'bold',
                                textTransform: 'capitalize',
                                fontSize: { xs: '18px', sm: '30px', md: '40px' },
                            }}
                        >
                            <div className=" montserrat-400">
                            We Make It Unreasonable 
                            </div>
                            <div className="montserrat-400 ">
                            For You To Not Get   <span> </span>
                                <span className="customgreen  cutom-underline"> Sales In The Next 30 Days! </span> 
                            </div>
                        </Typography>
                    {/* <Typography
                        variant="h2"
                        gutterBottom
                        className="montserrat-700" // Updated to Montserrat
                        sx={{ fontSize: { xs: '1.5rem', sm: '2.5rem', md: '3rem' } }} // Responsive font sizes
                    >
                        We Make It Unreasonable For You To Not Get Sales In The Next 37 Days!
                    </Typography> */}
                    <Typography
                        variant="h5"
                        gutterBottom
                        className="montserrat-300" // Updated to Montserrat
                        sx={{ fontSize: { xs: '1rem', sm: '1.5rem' } ,
                     textTransform: 'capitalize',}} // Responsive font sizes
                    >
                        "Our ad strategies elevate your brand and boost your sales!"
                    </Typography>
                    {/* <Typography
                        variant="body1"
                        gutterBottom
                        className="montserrat-200" // Updated to Montserrat
                        sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }} // Responsive font sizes
                    >
                        Partner with us to elevate your advertising game and watch your sales soar.
                    </Typography> */}
                    <Box mt={4}>
                    <Button
    onClick={handleButtonClick}
    variant="contained"
    size="medium" // Changed from 'large' to 'medium'
    sx={{
        textTransform: 'uppercase',
        borderRadius: '50px', // Reduced border radius for a more subtle shape
        padding: '10px 15px', // Reduced padding for smaller size
        fontSize: { xs: '12px', sm: '18px' }, // Adjusted font size for responsiveness
        fontWeight: '700',
        backgroundColor: '#37ca37',
        color: 'white',
        marginBottom: '25px',
        ':hover': {
            backgroundColor: '#37ca37',
            boxShadow: '0 0 10px rgba(255, 152, 0, 0.5), 0 0 20px rgba(255, 152, 0, 0.3)',
        },
    }}
>
    <div className="montserrat-700">
        Get More Sales Now
    </div>
</Button>

                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Hero1;
