import { Box } from "@mui/material"
import Navbar from "./navbar/Navbar"
import SecondSection from "./secondsection/SecondSection"
import ThirdSection from "./thirdsection/ThirdSection"
import Hero1 from "./hero/Hero1"
import BottomNav from "./bottomnav/BottomNav"
import FourthSection from "./fourtsection/FourthSection"

const Home = () => {
    return (
        <>

            <Box align='center' sx={{ padding: { xl: '0 200px' }, backgroundColor: '#000000' }}>
                <Box sx={{ maxWidth: '1500px' }}>
                    <Navbar />
                    <Hero1 />
                </Box>
            </Box>
            <Box align='center' sx={{ padding: { xl: '0 200px' }, backgroundColor: '#F5F7F8' }}>
                <Box sx={{ maxWidth: '1500px' }}>
                    <SecondSection />
                </Box>
            </Box>
            <Box align='center' sx={{ padding: { xl: '0 200px' }, backgroundColor: '#000000' }}>
                <Box align='left' sx={{ maxWidth: '1500px' }}>
                    <ThirdSection />
                </Box>
            </Box>
            <Box align='center' sx={{ padding: { xl: '0 200px' }, backgroundColor: '#F5F7F8' }}>
                <Box align='left' sx={{ maxWidth: '1500px' }}>
                    <FourthSection />
                    </Box>
                </Box>
                <Box align='center' sx={{ padding: { xl: '0 200px' }, backgroundColor: '#000000' }}>
                    <Box sx={{ maxWidth: '1500px' }}>
                        <BottomNav />
                    </Box>
                </Box>


            </>
            )
}

            export default Home;