import { Box, Button, Grid, Typography } from "@mui/material";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import React from "react";
import calendyUrl from "../global";

const ThirdSection = () => {

    // const openCalendlyPopup = () => {
    //     window.Calendly.initPopupWidget({ url: 'https://calendly.com/ishantsinghrajput143/appointment' }); return false;
    // };
    const handleButtonClick = () => {
        
        window.open(calendyUrl, '_blank', 'noopener,noreferrer');

    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={6} lg={6}>
                    <Box
                    id='services'
                        sx={{
                            padding: { xs: '30px', lg: '50px 120px' }
                        }}
                    >
                        <div >
                            <Typography

                                sx={{
                                    color: '#ffffff',
                                    fontSize: '28px'

                                }}
                            >
                                <div className="montserrat-400">
                                    What we offer
                                </div>

                            </Typography>

                            <Typography

                                sx={{
                                    color: '#ffffff',
                                    fontSize: '48px'

                                }}
                            >
                                <div className="montserrat-700 customgreen">
                                    PAID
                                </div>
                                <div className="montserrat-700 customgreen">
                                    ADVERTISING
                                </div>
                            </Typography>
                        </div>
                        <Typography
                            sx={{
                                fontSize: '18px'
                            }}>
                            <div className="montserrat-400">
                                Ads, Just Ads.
                            </div>
                            <br />
                            <div className="montserrat-400">
                                We do one thing - we just do it with a monastic focus and better than anyone else. If you want an agency that offers a full service solution of everything that won’t move the needle forward, we’re not for you.
                            </div>
                            <br />
                            <div className="montserrat-400">
                                If you want an agency where with two clicks, you can get a clear breakdown of how much was spent, how much was made & what your net profit was - we’re for you.
                            </div>
                        </Typography>
                        <br />
                        <Box align='center'>
                            <Button
                                onClick={handleButtonClick}
                                variant="contained"
                                size='large'
                                sx={{
                                    textTransform: 'uppercase',
                                    borderRadius: '100px',
                                    padding: '15px 18px',
                                    fontSize: { xs: '16px', sm: '20px', md: '23px', lg: '27px' },
                                    fontWeight: '700',
                                    backgroundColor: '#37ca37',
                                    color: 'white',
                                    marginBottom: '25px',
                                    ':hover': {
                                        backgroundColor: '#37ca37',
                                        boxShadow: '0 0 10px rgba(255, 152, 0, 0.5), 0 0 20px rgba(255, 152, 0, 0.3)'
                                    }

                                }}

                            >
                                <div className="montserrat-700">
                                    Speak to our team today
                                </div>
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid xs={12} sm={12} md={6} lf={6}>
                    <Box
                        sx={{
                            padding: { xs: '25px 30px 25px 30px', lg: '50px 120px' }
                        }}
                    >
                        <Box
                            sx={{
                                paddingTop: { xs: '', md: '180px' }
                            }}>
                            <Typography
                                sx={{
                                    color: '#ffffff',
                                    fontSize: '20px'

                                }}>
                                <div className="montserrat-700">
                                    MASTERY DEMANDS FOCUS SO....
                                </div>
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#ffffff',
                                    fontSize: '20px'

                                }}>
                                <div className="montserrat-200">
                                    We don't offer any other services
                                    <div> except for paid advertising...</div>
                                </div>
                            </Typography>
                            <br />
                            <Typography
                                sx={{
                                    color: '#ffffff',
                                    fontSize: {xs:'18px',sm:'20px'}

                                }}>
                                <div className="montserrat-700">
                                    <CancelOutlinedIcon sx={{
                                        color: '#ff1500',
                                        fontSize: 'large',
                                        fontWeight: '400'
                                    }} />    WEBDESIGN
                                </div>
                                <div className="montserrat-700">
                                    <CancelOutlinedIcon sx={{
                                        color: '#ff1500',
                                        fontSize: 'large',
                                        fontWeight: '400'
                                    }} />  CONTENT CREATION
                                </div>
                                <div className="montserrat-700">
                                    <CancelOutlinedIcon sx={{
                                        color: '#ff1500',
                                        fontSize: 'large',
                                        fontWeight: '400'
                                    }} />  EMAIL MARKETING
                                </div>
                                <div className="montserrat-700">
                                    <CancelOutlinedIcon sx={{
                                        color: '#ff1500',
                                        fontSize: 'large',
                                        fontWeight: '400'
                                    }} />  SOCIAL MEDIA MANAGEMENT
                                </div>
                                <div className="montserrat-700">
                                    <CancelOutlinedIcon sx={{
                                        color: '#ff1500',
                                        fontSize: 'large',
                                        fontWeight: '400'
                                    }} />  INSTAGRAM GROWTH
                                </div>
                                <div className="montserrat-700">
                                    <CancelOutlinedIcon sx={{
                                        color: '#ff1500',
                                        fontSize: 'large',
                                        fontWeight: '400'
                                    }} />  PR SERVICE
                                </div>
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default ThirdSection;