import { Box, Grid, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import React from "react";
import img from '../../assets/image/Mobile_inbox.gif'
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };



const SecondSection = () => {
    return (
        <>
            <div  >
                <Grid  >
                    <Typography
                        align="center"
                        variant="h4"
                        sx={{
                            paddingTop: '25px',
                            fontWeight: 'bold',
                            textTransform: 'capitalize',
                            fontSize: { xs: '32px', sm: '46px' }
                        }}
                    >
                        <div className="montserrat-700">
                            <span className="customgreen">Growth Solutions That Spark Joy</span>
                        </div>
                    </Typography>
                </Grid>
                <div>
                    <Box sx={{
                        padding:'20px'
                    }}>
                        <Grid container justifyContent='center' spacing={2}
                            sx={{
                                padding: {sm:'10px',lg:'20px'},
                                marginTop: '30px'
                            }}
                        >

                            <Grid xs={12} sm={12} md={6} >
                                <Box
                                    sx={{
                                        padding: {sm:'5px 45px 5px 45px',lg:'5px 45px 5px 45px'}
                                    }}
                                >
                                    <Typography
                                      
                                        align="center"
                                        sx={{
                                            fontSize: { xs: '20px', sm: '20px' },
                                            color: '#000000',
                                            fontWeight: '700',
                                            marginTop:{md:'80px'}
                                        }}
                                    >
                                        <div className="montserrat-700">
                                        Boost ROI/ROAS via Fb/Google Ads
                                        </div>
                                    </Typography>

                                    <Box sx={{
                                        margin: '25px',
                                       
                                    }}>


                                       
                                        

                                        <Typography
                                            className="poppins-regular-400"
                                            align='center'
                                            sx={{
                                                fontSize: { xs: '16px', sm: '20px',lg:'24px' },
                                                color: '#000000',
                                                fontWeight: '400',
                                            }}
                                        >
                                            <div className="montserrat-400">
                                            <Checkbox sx={{
                                                color: "#000000",
                                                '&.Mui-checked': {
                                                    color: "#37ca37",
                                                },
                                            }} {...label} defaultChecked />
                                            Boost your  <span className="customgreen custom-bold-700"> sales </span> attract more clients
                                            </div>
                                        </Typography> 
                                        

                                        <Typography
                                            className="poppins-regular-400"
                                            align='center'
                                            sx={{
                                                fontSize: { xs: '16px', sm: '20px',lg:'24px' },
                                                color: '#000000',
                                                fontWeight: '400',
                                            }}
                                        >
                                            <div className="montserrat-400">
                                            <Checkbox sx={{
                                                color: "#000000",
                                                '&.Mui-checked': {
                                                    color: "#37ca37",
                                                },
                                            }} {...label} defaultChecked />
                                            Maximize ROI with <span className="customgreen custom-bold-700">    smart ad </span> solutions
                                            </div>
                                        </Typography> 

                                        <Typography
                                            className="poppins-regular-400"
                                            align='center'
                                            sx={{
                                                fontSize: { xs: '16px', sm: '20px',lg:'24px' },
                                                color: '#000000',
                                                fontWeight: '400',
                                            }}
                                        >
                                            <div className="montserrat-400">
                                            <Checkbox sx={{
                                                color: "#000000",
                                                '&.Mui-checked': {
                                                    color: "#37ca37",
                                                },
                                            }} {...label} defaultChecked />
                                            Exclusive, <span className="customgreen custom-bold-700">qualified leads</span> at your fingertips.
                                            </div>
                                        </Typography> 
                                        
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid container justifyContent='center'  xs={12} sm={12} md={6}>
                                <Box
                                container
                                sx={{
                                    padding: {sm:'5px 20px 5px 45px',lg:'5px 45px 5px 45px'},
                                    backgroundColor:'#fff',
                                    mixBlendMode:'multiply'
                                    
                                }}>
                                    <img src={img} alt="mobile_image" />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
        </>
    )
}

export default SecondSection;