import { Box, Drawer, IconButton, Link, List, ListItemButton, ListItemText } from "@mui/material";
import React, { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import CustomMenuitems from "./CustomMenuitem";
const MyDrawer = () => {

    const [open, setOpen] = useState(false);
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    }

    const DrawerList = (
        <Box sx={{ width: 250, alignContent: 'center' }} role="presentation" onClick={toggleDrawer(false)}>
            <List sx={{ alignItems: 'center' }}>
                <IconButton >
                    <CloseIcon onClick={toggleDrawer(false)} sx={{ color: "#ffffff" }} />
                </IconButton>
                {CustomMenuitems.map((item) => (
                    <ListItemButton>
                        <Link href={item.path} sx={{ textDecoration: 'none' }}>
                            <ListItemText sx={{ color: '#ffffff' }} primary={item.name} />
                        </Link>

                    </ListItemButton>
                ))}

            </List>
        </ Box>
    )

    return (
        <div>
            {/* <Button onClick={toggleDrawer(true)}>Open drawer</Button> */}
            <IconButton >
                <MenuIcon onClick={toggleDrawer(true)} sx={{ color: "#ffffff" }} />
            </IconButton>
            <Drawer
                PaperProps={{
                    sx: {
                        backgroundColor: "black",
                        color: "#ffffff",
                    }
                }}
                anchor="right" open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </div>
    )
}

export default MyDrawer;