import React from "react";
import { AppBar, Box, Button, Link, Toolbar, useMediaQuery } from "@mui/material";
import { Typography } from "@mui/material";
import MyDrawer from "./MyDrawer";
import CustomMenuitems from "./CustomMenuitem";


const Navbar = () => {
    const isMatch = useMediaQuery('(max-width:768px)');

    return (

        <>
            <React.Fragment>

                <AppBar position="static" elevation={0}
                    sx={{
                        background: "#000000",
                        padding: { xs: '0px 10px', sm: '0px 25px', md: '0px 70px', lg: '0px 100px', xl: '0px 200px' }
                    }}>
                    <Toolbar>
                        <Typography sx={{
                            fontSizeL:'24px',
                            letterSpacing:'3px',
                            textDecoration:'none',
                            fontWeight:'700'
                            
                        }}>
                            <Link href='/' sx={{textDecoration:'none',color:'#ffffff'}}>
                            <div className="custom-font">

                            GrowitUp
                            </div>
                        </Link>
                        </Typography>
                        {
                            isMatch ? (<>
                                <Box sx={{ marginLeft: 'auto' }}>
                                    <MyDrawer />
                                </Box>

                            </>) : (<>
                                <Box sx={{ marginLeft: 'auto' }}>
                                    {
                                        CustomMenuitems.map((items) => (
                                            <Link  href={items.path}>
                                                <Button  sx={{ margin: { sm: 'auto', md: '7px', ld: '12px', xl: '15px',textTransform:'none' }, color: '#ffffff' }} key={items.name}>{items.name}</Button>
                                            </Link>
                                        ))
                                    }


                                </Box>
                            </>)
                        }

                    </Toolbar>
                </AppBar>

            </React.Fragment>
        </>
    )
}
export default Navbar;