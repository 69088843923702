import { Box, Button, Typography } from "@mui/material";
import React from "react";

const Disclaimer = () => {
    return (
        <div style={{ backgroundColor: '#000000', padding: '40px', minHeight: '100vh' }}>
            <Box 
                align='center' 
                justifyContent='center' 
                sx={{ 
                    width: { xs: '83%', md: '60%' }, 
                    margin: 'auto', 
                    padding: '20px', 
                    backgroundColor: '#000000', // Black background for the box
                    borderRadius: '10px', 
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)' 
                }}
            >
                <Typography align="center" sx={{ fontSize: { xs: '24px', sm: '36px' }, fontWeight: '700', marginBottom: '20px', color: '#ffffff' }}>
                    Disclaimer
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        The information provided by GrowitUp (growitup.in) is for general informational purposes only. All information on the site is provided in good faith; however, we make no representation or warranty of any kind, express or implied.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        We do not assume any liability for the completeness, reliability, and accuracy of this information. Any action you take upon the information you find on this website (GrowitUp) is strictly at your own risk. GrowitUp will not be liable for any losses and/or damages in connection with the use of our website.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        In no event shall we be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:
                    </div>
                </Typography>

                <ul style={{ marginLeft: '0', marginBottom: '20px', color: '#ffffff', paddingLeft: '20px', textAlign: 'left' }}>
                    <li>The use or inability to use our service</li>
                    <li>Any unauthorized access to or use of our servers and/or any personal information stored therein</li>
                    <li>Any interruption or cessation of transmission to or from our service</li>
                    <li>Any bugs, viruses, or the like that may be transmitted to or through our service by any third party</li>
                    <li>Any errors or omissions in any content or for any loss or damage of any kind incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available via our service</li>
                </ul>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        GrowitUp reserves the right to make additions, deletions, or modification to the contents on the service at any time without prior notice. However, we have no obligation to update any information on our service. We also do not warrant that the website will be available at all times.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        The content on this site may contain inaccuracies or typographical errors. GrowitUp does not warrant the accuracy or completeness of any information, text, graphics, links, or other items contained within the service.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        We do not endorse any opinions expressed in the content of this website. The views and opinions expressed in articles, blogs, and comments are solely those of the authors and do not necessarily reflect the views of GrowitUp.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        Links to external websites are provided for your convenience and do not signify endorsement. GrowitUp has no control over the content of these websites and cannot be held responsible for any issues arising from your visit to them.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        By using our website, you hereby consent to our disclaimer and agree to its terms. If you do not agree with any part of this disclaimer, you must not use our website.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        The information contained on this website is not intended to be a substitute for professional advice. Always seek the advice of your physician, psychologist, or other qualified health provider with any questions you may have regarding a medical condition.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        The views and opinions expressed in the content available through the service are those of the authors and do not necessarily reflect the official policy or position of GrowitUp. 
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        GrowitUp makes no guarantees regarding the effectiveness of any services provided. The success of our services depends on various factors that may be beyond our control.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        Users are encouraged to do their own research and seek independent advice when considering any services or information presented on our site.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        GrowitUp is not responsible for any decision made or action taken based on the information provided on the site. Any reliance you place on such information is therefore strictly at your own risk.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        This disclaimer may be updated from time to time to reflect changes to our policies and procedures. We encourage you to periodically review this page for the latest information on our practices.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        If you have any questions about this disclaimer or our practices regarding your information, please contact us at <a href="mailto:hello@growitup.in" style={{ color: '#37ca37' }}>hello@growitup.in</a>.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        By using this site, you acknowledge that you have read this disclaimer and agree to be bound by its terms.
                    </div>
                </Typography>

                <Button
                    type="submit"
                    onClick={() => window.location.href = '/'} // Adjust to your homepage URL
                    sx={{
                        margin: '5px',
                        color: '#37ca37', 
                        borderColor: '#37ca37', // Green border color
                        ':hover': {
                            boxShadow: '0 0 10px rgba(255, 152, 0, 0.5), 0 0 20px rgba(255, 152, 0, 0.3)'
                        }
                    }}
                    variant="outlined"
                >
                    <div className="montserrat-400">
                        Give Me MY Free GUIDE
                    </div>
                </Button>
            </Box>
        </div>
    );
}

export default Disclaimer;
