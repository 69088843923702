import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import img from '../../assets/matrix.webp';

const FourthSection = () => {


    return (
        <><Box sx={{
            padding: { xs: '20px 0px', md: '25px' },

        }}>
            <div>
                <Box sx={{
                    padding: { xs: '0px', md: '0px 100px' }
                }}>
                    <Typography
                        align="center"
                        sx={{
                            color: '#000000',
                            letterSpacing: '3px',
                            fontSize: { xs: '16px', md: '22px' },
                        }}
                    >
                        <div className="montserrat-700">
                            FREE GUIDE FOR YOU:
                        </div>
                    </Typography>
                    <br />
                    <Typography
                        align="center"
                        sx={{
                            color: '#000000',
                            fontSize: { xs: '21px', md: '36px' },
                        }}
                    >
                        <div className="montserrat-700 customgreen">
                            "How My Agency Is Helping Our client Businesses Scale Aggressively"
                        </div>
                    </Typography>
                </Box>
                <Box sx={{
                    padding: '25px 0px'
                }}>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12} md={6}>
                            <Typography
                                align="center"
                                sx={{
                                    color: '#000000',
                                    letterSpacing: '3px',
                                    fontSize: { xs: '16px', md: '22px' },
                                }}
                            >
                                
                                <img src={img} alt="mobile_image" />
                                    
                                
                            </Typography>
                        </Grid>
                        <Grid xs={12} sm={12} md={6}>
                            <Box sx={{
                                padding: '50px'
                            }}>
                                <Typography

                                    sx={{
                                        color: '#000000',
                                        fontSize: { xs: '16px', md: '20px' },
                                    }}
                                >
                                    <div className="montserrat-400 ">
                                        How we consistently generate spine chilling ROI for our clients.
                                    </div>
                                </Typography>
                                <br />
                                <Typography

                                    sx={{
                                        color: '#000000',
                                        fontSize: { xs: '16px', md: '20px' },
                                    }}
                                >
                                    <div className="montserrat-400 ">
                                        How to achieve 'god like' omni-presence & dominate your competition.
                                    </div>
                                </Typography>
                                <br />
                                <Typography

                                    sx={{
                                        color: '#000000',
                                        fontSize: { xs: '16px', md: '20px' },
                                    }}
                                >
                                    <div className="montserrat-400 ">
                                        Why you need to 'plug the holes' in your sales process before you can drive traffic.
                                    </div>
                                </Typography>
                                <br />
                                <Box align='center'>
                                    <Button
                                        href="/free-guide"
                                        variant="contained"
                                        size='large'
                                        sx={{
                                            textTransform: 'uppercase',
                                            borderRadius: '100px',
                                            padding: '15px 18px',
                                            fontSize: { xs: '16px', sm: '20px', md: '20px', lg: '27px' },
                                            fontWeight: '700',
                                            backgroundColor: '#37ca37',
                                            color: 'white',
                                            marginBottom: '25px',
                                            ':hover': {
                                                backgroundColor: '#37ca37',
                                                boxShadow: '0 0 10px rgba(255, 152, 0, 0.5), 0 0 20px rgba(255, 152, 0, 0.3)'
                                            }

                                        }}

                                    >
                                        <div className="montserrat-700">
                                            GIVE MY FREE GUIDE                                </div>
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </Box>
        </>
    )
}

export default FourthSection;