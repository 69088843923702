import { Box, Button, Typography } from "@mui/material";
import React from "react";

const PrivacyPolicy = () => {
    return (
        <div style={{ backgroundColor: '#000000', padding: '40px', minHeight: '100vh' }}>
            <Box 
                align='center' 
                justifyContent='center' 
                sx={{ 
                    width: { xs: '83%', md: '60%' }, 
                    margin: 'auto', 
                    padding: '20px', 
                    backgroundColor: '#000000', 
                    borderRadius: '10px', 
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)' 
                }}
            >
                <Typography align="center" sx={{ fontSize: { xs: '24px', sm: '36px' }, fontWeight: '700', marginBottom: '20px', color: '#ffffff' }}>
                    Privacy Policy
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        At GrowitUp, we value your privacy. This Privacy Policy outlines how we collect, use, and protect your personal information when you visit our website, growitup.in. 
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        <strong>Information We Collect:</strong> We collect various types of information to provide better services to our users. This includes:
                        <ul style={{ color: '#ffffff' }}>
                            <li>Your name</li>
                            <li>Email address</li>
                            <li>Phone number</li>
                            <li>Postal address</li>
                            <li>Payment information (if applicable)</li>
                            <li>Other data you provide through forms or surveys</li>
                        </ul>
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        <strong>How We Use Your Information:</strong> The information we collect may be used in the following ways:
                        <ul style={{ color: '#ffffff' }}>
                            <li>To personalize your experience on our site</li>
                            <li>To improve our website based on your feedback</li>
                            <li>To process transactions efficiently</li>
                            <li>To send periodic emails regarding your order or other products and services</li>
                            <li>To respond to inquiries, and/or other requests or questions</li>
                        </ul>
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        <strong>Cookies:</strong> We use cookies to enhance user experience, analyze site usage, and assist in our marketing efforts. Cookies are small files placed on your device. You can manage your cookie preferences through your browser settings.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        <strong>Data Security:</strong> We implement a variety of security measures to maintain the safety of your personal information. Your information is stored behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        <strong>Third-Party Services:</strong> We may employ third-party companies and services to facilitate our service, provide the service on our behalf, perform service-related services, or assist us in analyzing how our service is used. 
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        <strong>Children’s Privacy:</strong> Our services are not intended for use by children under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your child has provided us with Personal Data, please contact us.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        <strong>Your Rights:</strong> You have the right to:
                        <ul style={{ color: '#ffffff' }}>
                            <li>Access your personal data</li>
                            <li>Request correction of inaccurate data</li>
                            <li>Request deletion of your personal data</li>
                            <li>Object to processing your personal data</li>
                            <li>Request transfer of your personal data</li>
                        </ul>
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        <strong>Changes to Our Privacy Policy:</strong> We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this policy periodically for any changes.
                    </div>
                </Typography>

                <Typography align="left" sx={{ fontSize: { xs: '14px', sm: '18px' }, marginBottom: '20px', color: '#ffffff' }}>
                    <div className="montserrat-400">
                        <strong>Contact Us:</strong> If you have any questions about this Privacy Policy, please contact us at <a href="mailto:hello@growitup.in" style={{ color: '#37ca37' }}>hello@growitup.in</a>.
                    </div>
                </Typography>

                <Button
                    type="submit"
                    onClick={() => window.location.href = '/'} // Adjust to your homepage URL
                    sx={{
                        margin: '5px',
                        color: '#37ca37', 
                        borderColor: '#37ca37',
                        ':hover': {
                            boxShadow: '0 0 10px rgba(255, 152, 0, 0.5), 0 0 20px rgba(255, 152, 0, 0.3)'
                        }
                    }}
                    variant="outlined"
                >
                    <div className="montserrat-400">
                        Give Me MY Free GUIDE
                    </div>
                </Button>
            </Box>
        </div>
    );
}

export default PrivacyPolicy;
