import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import CaseStudyVideo from './CaseStudyVideo';
// import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import DownloadSuccess from './DownloadSuccess'; // Import the new component
import img from '../../assets/matrix.webp';
import pdf from '../../assets/ppp.pdf'

const down = async () => {
    // const domain = document.location.host; // Ensure this is set correctly
    // const resourceUrl = `https://${domain}/growitup.pdf`; // Confirm this URL is correct

    try {
        const link = document.createElement('a');
    link.href = pdf;
    link.download = 'growitup.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
        // const fileResponse = await fetch(resourceUrl);
        
        // console.log('Response Status:', fileResponse.status);
        // console.log('Content-Type:', fileResponse.headers.get('Content-Type'));

        // if (!fileResponse.ok) {
        //     throw new Error(`Network response was not ok: ${fileResponse.statusText}`);
        // }

        // // Check if the content type is correct
        // const contentType = fileResponse.headers.get('Content-Type');
        // if (contentType !== 'application/pdf') {
        //     const text = await fileResponse.text(); // Read the response as text
        //     throw new Error(`Expected PDF but received: ${contentType}. Response: ${text}`);
        // }

        // const blob = await fileResponse.blob(); 
        // const url = window.URL.createObjectURL(blob); 
        
        // const link = document.createElement('a');
        // link.href = pdf;
        // link.download = 'growitup.pdf'; 
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        // window.URL.revokeObjectURL(url); 
    } catch (error) {
        console.error('Error fetching the resource:', error);
    }
};



const CaseStudy = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [emailSubmitted, setEmailSubmitted] = useState(false); // Track email submission status
    // const navigate = useNavigate(); // Initialize useNavigate hook

    const handleSubmit = async (e) => {
       
        e.preventDefault();
        
        // Convert email to lowercase
        const lowerCaseEmail = email.toLowerCase();
        
        const isValid = /\S+@\S+\.\S+/.test(lowerCaseEmail);
        if (!isValid) {
            setError('Please enter a valid email address');
            return;
        }
    
        try {
            const response = await fetch('https://backend-8k1u.onrender.com/api/submit-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: lowerCaseEmail }), // Use the lowercase email here
            });
    
            if (response.ok) {
                setEmail('');
                setError('');
                down();
                setEmailSubmitted(true); 
            } else {
                if (response.status === 409) {
                    down();
                    setEmailSubmitted(true); 
                } else {
                    throw new Error(`Failed to submit email: ${response.statusText}`);
                }
            }
        } catch (error) {
            console.error('Error submitting email:', error);
            setError('Failed to submit email. Please try again later.');
        }
    };
    

    const handleChange = (e) => {
        setEmail(e.target.value); // Update the email state with the new input value
        setError(''); // Clear any existing error message
    };

    return (
        <>
            <div>
                {
                    emailSubmitted ? (<>
                        {/* < CaseStudyVideo /> */}
                        <DownloadSuccess />

                    </>) : (<>  <Box
                        display="flex"
                        align="center"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            padding: 'auto',
                            height: { xs: 'auto', md: '690px', lg: '100vh' },
                            backgroundColor: '#000000',
                        }}
                    >

                        <Box
                            sx={{
                                width: { xs: '373px', sm: '690px', md: '900px', lg: '1118px' },
                                height: { xs: '949.242px', sm: '890px', md: '633.141px', lg: '560.375px' },
                                backgroundColor: '#000000',
                                border: 'solid 1px #2F3645',
                                borderRadius: '10px',
                                padding: '20px',
                            }}
                        >

                            <Typography
                                sx={{
                                    color: '#ffffff',
                                    fontSize: { xs: '18px', md: '20px' },
                                    letterSpacing: '1px',
                                }}
                            >
                                <div className="montserrat-700 customgreen">
                                    FREE GUIDE 
                                </div>
                            </Typography>
                            <Typography sx={{ color: '#ffffff', fontSize: { xs: '23px', md: '34px' } }}>
                                <div className="montserrat-700 ">
                                    "How My Agency Has Consistently Generated Spine-Chilling ROI
                                    For Our Clients"
                                </div>
                            </Typography>
                            <Box sx={{ padding: '24px 0px' }}>
                                <Grid container spacing={2}>
                                    <Grid xs={12} sm={12} md={6}>
                                    <img src={img} alt="mobile_image" />
                                    </Grid>
                                    <Grid xs={12} sm={12} md={6}>
                                        <Box sx={{ padding: '20px' }}>
                                            <Typography
                                                align="left"
                                                sx={{
                                                    color: '#ffffff',
                                                    fontSize: { xs: '16px', md: '20px' },
                                                }}
                                            >
                                                <div className="montserrat-400 ">
                                                    <CheckCircleOutlineOutlinedIcon
                                                        sx={{
                                                            color: '#37ca37',
                                                            fontSize: 'medium',
                                                        }}
                                                    />{' '}
                                                    How we consistently generate spine chilling ROI for our
                                                    clients.
                                                </div>
                                            </Typography>

                                            <Typography
                                                align="left"
                                                sx={{
                                                    color: '#ffffff',
                                                    fontSize: { xs: '16px', md: '20px' },
                                                }}
                                            >
                                                <div className="montserrat-400 ">
                                                    <CheckCircleOutlineOutlinedIcon
                                                        sx={{
                                                            color: '#37ca37',
                                                            fontSize: 'medium',
                                                        }}
                                                    />{' '}
                                                    How to achieve 'god like' omni-presence & dominate your
                                                    competition.
                                                </div>
                                            </Typography>

                                            <Typography
                                                align="left"
                                                sx={{
                                                    color: '#ffffff',
                                                    fontSize: { xs: '16px', md: '20px' },
                                                }}
                                            >
                                                <div className="montserrat-400 ">
                                                    <CheckCircleOutlineOutlinedIcon
                                                        sx={{
                                                            color: '#37ca37',
                                                            fontSize: 'medium',
                                                        }}
                                                    />{' '}
                                                    Why you need to 'plug the holes' in your sales process
                                                    before you can drive traffic.
                                                </div>
                                            </Typography>
                                            <br />
                                            <Box align="left">
                                                {!emailSubmitted ? ( // Render form if email is not submitted
                                                    <form onSubmit={handleSubmit}>
                                                        <TextField
                                                            required
                                                            value={email}
                                                            onChange={handleChange}
                                                            error={!!error}
                                                            helperText={error}
                                                            sx={{
                                                                width: '90%',
                                                                backgroundColor: '#000000',
                                                                borderRadius: '7px',
                                                                margin: '5px',
                                                            }}
                                                            id="email"
                                                            label="Your Email Address"
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: 'white',
                                                                }, // Adjust label color to contrast with white background
                                                            }}
                                                            InputProps={{
                                                                style: {
                                                                    color: 'white',
                                                                }, // Adjust input text color if necessary
                                                            }}
                                                        />
                                                        <br />
                                                        <Button
                                                            type="submit"
                                                            // href={pdf}
                                                            sx={{
                                                                width: '90%',
                                                                margin: '5px',
                                                                color: '#37ca37', 
                                                                borderColor: '#37ca37', // Green border color
                                                                // backgroundColor: '#37ca37',
                                                                ':hover': {
                                                                    // backgroundColor: '#37ca37',
                                                                    boxShadow: '0 0 10px rgba(255, 152, 0, 0.5), 0 0 20px rgba(255, 152, 0, 0.3)'
                                                                }
                                                            }}
                                                            variant="outlined"
                                                        >
                                                            <div className="montserrat-400">
                                                                Give Me MY Free GUIDE
                                                            </div>
                                                        </Button>
                                                        
                                                        
                                                    </form>
                                                ) : (
                                                    // Render alternative content if email is submitted
                                                    <Typography sx={{ color: '#ffffff', fontSize: '20px' }}>
                                                        Thank you for submitting your email! You can now access our
                                                        free guide.
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>


                        </Box>
                    </Box>
                    </>)
                }
            </div>
        </>
    );
};

export default CaseStudy;
