import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import './App.css';
import { About, Home } from './components';
import { Route, Routes } from 'react-router-dom';
import CaseStudy from './components/casestudy/CaseStudy';
import PrivacyPolicy from './components/legal/PrivacyPolicy';
import Disclaimer from './components/legal/Disclaimer';
import TermsConditions from './components/legal/TermsConditions';
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (

    <ThemeProvider theme={darkTheme}>
      <CssBaseline />

      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/free-guide' element={<CaseStudy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />

        <Route path='*' element={<Home />} />
      </Routes>

    </ThemeProvider>

  );
}

export default App;
